import React, { useRef, useState } from "react";
import "../../styles/forgotPassword2.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import request from "../../utils/request";
import NotificationAlert from "react-notification-alert";
import ReCAPTCHA from "react-google-recaptcha";

const ForgotPassword2 = () => {
  const { userName, server } = useParams();
  const [passwordLv2, setPasswordLv2] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const notificationAlertRef = useRef(null);

  const key = "6Lfkk7cpAAAAAIzmL-B2lUDNEcTXd_brvhDmMrfV";
  const recaptchaRef = useRef(null);
  const navigate = useNavigate();

  const notifySuccess = () => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            Mật khẩu đã được đặt lại thành công.
          </span>
        </div>
      ),
      type: "success",
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const notifyDanger = (message) => {
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {message.title}
          </span>
          <br />
          <span data-notify="message">{message.details}</span>
        </div>
      ),
      type: "danger",
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const captchaToken = recaptchaRef.current.getValue(); // Lấy token từ reCAPTCHA
    if (!captchaToken) {
      // Xử lý trường hợp reCAPTCHA chưa được hoàn thành
      return;
    }

    if (!passwordLv2 || !newPassword || !confirmNewPassword) {
      notifyDanger({
        title: "Các trường không được bỏ trống!",
        details: "Vui lòng điền đầy đủ thông tin.",
      });
      return;
    }

    if (newPassword.length < 6) {
      notifyDanger({
        title: "Mật khẩu quá ngắn!",
        details: "Mật khẩu mới phải có ít nhất 6 ký tự.",
      });
      return;
    }

    if (newPassword !== confirmNewPassword) {
      notifyDanger({
        title: "Mật khẩu mới và xác nhận mật khẩu mới không khớp!",
        details: "Vui lòng kiểm tra các trường đã điền",
      });
      return;
    }

    try {
      const response = await request.put(`/Auth/ForgotPassword?server=${server}`, {
        Username: userName,
        PasswordLv2: passwordLv2,
        NewPassword: newPassword,
      });

      if (response.status === 200) {
        notifySuccess();
        navigate("/login");
      } else {
        throw new Error(`API responded with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error resetting password:", error);

      let errorMessage = "Xin vui lòng thử lại!";
      if (error.response) {
        // Kiểm tra các status code cụ thể
        if (error.response.status === 429) {
          errorMessage =
            "Bạn đã nhập sai quá nhiều lần. Vui lòng thử lại sau 10 phút.";
        } else {
          errorMessage = error.response.data?.message || errorMessage;
        }
      }
      notifyDanger({
        title: "Mã bảo vệ sai.",
        details: errorMessage,
      });
    }
  };

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <div className="forgot-password2-container">
        <div className="form-forgot-password2">
          <h1>QUÊN MẬT KHẨU</h1>
          <form onSubmit={handleSubmit}>
            <div className="form">
              <label>Tài khoản</label>
              <input
                type="text"
                className="form-control"
                placeholder="Nhập tên tài khoản"
                value={userName}
                readOnly={true}
              />
            </div>
            <div className="form">
              <label>Mã bảo vệ</label>
              <input
                type="text"
                className="form-control"
                placeholder="Nhập mã bảo vệ"
                value={passwordLv2}
                onChange={(e) => setPasswordLv2(e.target.value)}
              />
            </div>
            <div className="form">
              <label>Mật khẩu mới</label>
              <input
                type="password"
                className="form-control"
                placeholder="Mật khẩu"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>
            <div className="form">
              <label>Xác nhận mật khẩu mới</label>
              <input
                type="password"
                className="form-control"
                placeholder="Mật khẩu"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
              />
            </div>

            <div className="link">
              <Link to={"/login"}>Đăng nhập</Link>
              <Link to={"/register"}>Đăng ký</Link>
            </div>
            <ReCAPTCHA sitekey={key} ref={recaptchaRef} />

            <button type="submit">Tiếp tục</button>
          </form>
        </div>
      </div>
    </>
  );
};
export default ForgotPassword2;
