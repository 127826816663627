import payment from "../../assets/img/image 8.png";
import history from "../../assets/img/image 7.png";
import React from "react";
import "../../styles/headerPayment.css";
import { Link } from "react-router-dom";
import Information from "../auth/Infomation";
const HeaderPayment = () => {
  return (
    <div className="header-payment-container">
      <Information />
      <h1>NẠP THẺ</h1>
      <div className="header-payment">
        <Link to="/payment-option">
          <div className="left">
            <img src={payment} alt="" />
            <p>Phương thức nạp</p>
          </div>
        </Link>
        <Link to="/payment-history">
          <div className="right">
            <img src={history} alt="" />
            <p>Lịch sử giao dịch</p>
          </div>
        </Link>
      </div>
    </div>
  );
};
export default HeaderPayment;
