import { useEffect, useState } from "react";
import request from "../../utils/request";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";

const Information = () => {
  const [userData, setUserData] = useState(null);
  const [girlkunCoins, setGirlkunCoins] = useState(0);
  const navigate = useNavigate();

  const handleLogout = () => {
    Cookies.remove("USER_DATA");
    navigate("/");
  };

  useEffect(() => {
    try {
      const userDataString = Cookies.get("USER_DATA");
      if (userDataString) {
        setUserData(JSON.parse(userDataString)); // Update userData through state
      }
    } catch (error) {
      console.error("Error parsing USER_DATA:", error);
    }
  }, []);
  useEffect(() => {
    // Ensure userData is not null and has a username property before making the request
    if (userData && userData.username) {
      request
        .get(`Auth/CheckUsername?username=${userData.username}&server=${userData.server}`)
        .then((response) => {
          setGirlkunCoins(response.data.girlkunCoin);
        })
        .catch((error) => {
          console.error("Error fetching girlkuncoins:", error);
        });
    }
  }, [userData]);
  return (
    <div className="information-container">
      <p>Tài khoản: {userData ? userData.username : ""}</p>
      <p style={{ color: "#DEFF10" }}>
        {girlkunCoins !== undefined &&
          girlkunCoins.toLocaleString("vi-VN", {
            style: "decimal",
          })}{" "}
        ToriCoins
      </p>
      {userData && (
        <Link to={`/change-password/${userData.username}`}>Đổi mật khẩu</Link>
      )}
      <a href="/login" onClick={handleLogout}>
        Đăng xuất
      </a>
    </div>
  );
};
export default Information;
