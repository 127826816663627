import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import request from "../../utils/request";
import Cookies from "js-cookie";
import "../../styles/ranking.css";

const Ranking = () => {
  const [power, setPower] = useState([]); // Lưu trữ dữ liệu ranking
  const [payments, setPayments] = useState([]);
  const [petPower, setPetPower] = useState([]);
  const [gold, setGold] = useState([]);
  const [boss, setBoss] = useState([]);
  const [event, setEvent] = useState([]);

  let userData = null;
  try {
    const userDataString = Cookies.get("USER_DATA");
    if (userDataString) {
      userData = JSON.parse(userDataString);
    }
  } catch (error) {
    console.error("Error parsing ACCOUNT_DATA:", error);
  }

  useEffect(() => {
    const fetchRankings = async () => {
      try {
        const response = await request.get(
          `/Ranking/TopPower?server=${userData.server}`
        );
        const response1 = await request.get(
          `/Ranking/TopPayment?server=${userData.server}`
        );
        const response2 = await request.get(
          `/Ranking/TopPetPower?server=${userData.server}`
        );
        const response3 = await request.get(
          `/Ranking/TopGold?server=${userData.server}`
        );
        const response4 = await request.get(
          `/Ranking/TopBoss?server=${userData.server}`
        );
        const response5 = await request.get(
          `/Ranking/TopEvent?server=${userData.server}`
        );
        setPower(response.data);
        setPayments(response1.data);
        setPetPower(response2.data);
        setGold(response3.data);
        setBoss(response4.data);
        setEvent(response5.data);
      } catch (error) {
        console.error("Lỗi khi gọi API:", error);
        // Xử lý lỗi ở đây (ví dụ: hiển thị thông báo lỗi)
      }
    };

    fetchRankings(); // Gọi hàm fetchRankings khi component được mount
  }, []);

  return (
    <div className="ranking-container">
      {userData.server === "server1" ? (
        <>
          <h3 style={{ marginTop: "20px" }}>BẢNG XẾP HẠNG SỰ KIỆN</h3>
          <Table hover bordered style={{ width: "80%", margin: "30px auto" }}>
            <thead>
              <tr>
                <th>Thứ tự</th>
                <th>Nhân vật</th>
                <th>Điểm</th>
              </tr>
            </thead>
            <tbody>
              {event.map((e, index) => (
                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <td>{e.name}</td>
                  <td>
                    {e.event.toLocaleString("en-US", { style: "decimal" })}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      ) : null}

      {/* {/* <h3 style={{ marginTop: "20px" }}>BẢNG XẾP HẠNG SỨC MẠNH</h3>
      <Table hover bordered style={{ width: "80%", margin: "30px auto" }}>
        <thead>
          <tr>
            <th>Thứ tự</th>
            <th>Nhân vật</th>
            <th>Sức mạnh</th>
          </tr>
        </thead>
        <tbody>
          {power.map((ranking, index) => (
            <tr key={index}>
              <th scope="row">{index + 1}</th>
              <td>{ranking.name}</td>
              <td>
                {ranking.power.toLocaleString("en-US", { style: "decimal" })}
              </td>
            </tr>
          ))}
        </tbody>
      </Table> */}
      {userData.server === "server2" ? (
        <>
          <h3 style={{ marginTop: "20px" }}>BẢNG XẾP HẠNG NẠP TIỀN</h3>
          <Table hover bordered style={{ width: "80%", margin: "30px auto" }}>
            <thead>
              <tr>
                <th>Thứ tự</th>
                <th>Nhân vật</th>
                <th>Số tiền</th>
              </tr>
            </thead>
            <tbody>
              {payments.map((ranking, index) => (
                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <td>{ranking.name}</td>
                  <td>
                    {ranking.totalValue.toLocaleString("vi-VN", {
                      style: "decimal",
                    })}{" "}
                    VNĐ
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      ) : null}

      {/* <h3 style={{ marginTop: "20px" }}>BẢNG XẾP HẠNG SỨC MẠNH ĐỆ TỬ</h3>
      <Table hover bordered style={{ width: "80%", margin: "30px auto" }}>
        <thead>
          <tr>
            <th>Thứ tự</th>
            <th>Nhân vật</th>
            <th>Sức mạnh</th>
          </tr>
        </thead>
        <tbody>
          {petPower.map((ranking, index) => (
            <tr key={index}>
              <th scope="row">{index + 1}</th>
              <td>{ranking.name}</td>
              <td>
                {ranking.power.toLocaleString("en-US", { style: "decimal" })}
              </td>
            </tr>
          ))}
        </tbody>
      </Table> */}
      {userData.server === "server1" ? (
        <>
          <h3 style={{ marginTop: "20px" }}>BẢNG XẾP HẠNG NHẶT VÀNG</h3>
          <Table hover bordered style={{ width: "80%", margin: "30px auto" }}>
            <thead>
              <tr>
                <th>Thứ tự</th>
                <th>Nhân vật</th>
                <th>Vàng</th>
              </tr>
            </thead>
            <tbody>
              {gold.map((ranking, index) => (
                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <td>{ranking.name}</td>
                  <td>
                    {ranking.power.toLocaleString("en-US", {
                      style: "decimal",
                    })}{" "}
                    Gold
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      ) : null}
    </div>
  );
};
export default Ranking;
