import Cookies from "js-cookie";
import { useRef, useState } from "react";
import NotificationAlert from "react-notification-alert";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import "../../styles/charge.css";
import "../../styles/information.css";
import request from "../../utils/request";
import Information from "../auth/Infomation";
const MomoPayment = () => {
  const notificationAlertRef = useRef(null);
  const [momoInfo, setMomoInfo] = useState({
    amount: "",
  });

  const [qrCodeData, setQrCodeData] = useState("");
  let userData = null;
  try {
    const userDataString = Cookies.get("USER_DATA");
    if (userDataString) {
      userData = JSON.parse(userDataString);
    }
  } catch (error) {
    console.error("Error parsing ACCOUNT_DATA:", error);
  }

  const notifySuccess = () => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            Xin vui lòng chờ để hệ thống xử lý thông tin thẻ.
          </span>
        </div>
      ),
      type: "success",
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const notifyDanger = () => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            Thông tin thẻ không chính xác!{" "}
          </span>
          <br />
          <span data-notify="message">Vui lòng kiểm tra lại</span>
        </div>
      ),
      type: "danger",
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setMomoInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let response = null;
      if (userData.server === "server2") {
        response = await request.get(
          `/MomoPayment/Recharge2?amount=${momoInfo.amount}&accountId=${userData.id}`
        );
      } else {
        response = await request.get(
          `/MomoPayment/Recharge?amount=${momoInfo.amount}&accountId=${userData.id}`
        );
      }
      if (response.data) {
        setQrCodeData(response.data.data.qrcode);
        notifySuccess();
      } else {
        notifyDanger();
      }
    } catch (error) {
      console.error("Error when sending card:", error);
      notifyDanger();
    }
  };

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <div className="charge">
        <Information />
        <h1>NẠP TIỀN QUA MOMO</h1>
        <Form className="form-charge" onSubmit={handleSubmit}>
          <FormGroup>
            <Label for="name">Loại thẻ:</Label>
            <Input
              id="exampleSelect"
              name="telco"
              type="select"
              onChange={handleChange}
            >
              <option value="Momo">Momo</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="exampleText">Mệnh giá thẻ:</Label>
            <Input
              id="exampleSelect"
              name="amount"
              onChange={handleChange}
              type="select"
              required
            >
              <option value="">Chọn mệnh giá thẻ</option>
              <option value="10000">10,000 (+10%) VNĐ</option>
              <option value="20000">20,000 (+10%) VNĐ</option>
              <option value="50000">50,000 (+10%) VNĐ</option>
              <option value="100000">100,000 (+10%) VNĐ</option>
              <option value="200000">200,000 (+12%) VNĐ</option>
              <option value="500000">500,000 (+12%) VNĐ</option>
              <option value="1000000">1,000,000 (+15%) VNĐ</option>
              <option value="2000000">2,000,000 (+15%) VNĐ</option>
              <option value="5000000">5,000,000 (+17%) VNĐ</option>
              <option value="10000000">10,000,000 (+20%) VNĐ</option>
              <option value="20000000">20,000,000 (+23%) VNĐ</option>
            </Input>
          </FormGroup>
          <div className="form-button">
            <Button type="submit" className="btn btn-success">
              Thực hiện
            </Button>
          </div>
          {qrCodeData && (
            <div className="qr-code-container">
              <h2>QUÉT MÃ QR ĐỂ THANH TOÁN</h2>
              <img
                style={{ width: "300px" }}
                src={qrCodeData}
                alt="QR Code for payment"
              />
            </div>
          )}
        </Form>
      </div>
    </>
  );
};
export default MomoPayment;
