import React, { useRef, useState } from "react";
import "../../styles/forgotPassword.css";
import { Link, useNavigate } from "react-router-dom";
import request from "../../utils/request";
import NotificationAlert from "react-notification-alert";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState();
  const [server, setServer] = useState("server1");
  const notificationAlertRef = useRef(null);

  const notifyDanger = () => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            
            Không tìm thấy tài khoản! {" "}
          </span>
          <br />
          <span data-notify="message">
            Vui lòng kiểm tra các trường đã điền
          </span>
        </div>
      ),
      type: "danger",
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await request.get(
        `/Auth/CheckUsername?username=${username}&server=${server}`
      );
      if (response.status === 200) {
        navigate(`/forgot-password/${username}/${server}`);
      } else {
        notifyDanger();
      }
    } catch (err) {
      notifyDanger();
    }
  };
  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <div className="forgot-password-container">
        <div className="form-forgot-password">
          <h1>QUÊN MẬT KHẨU</h1>
          <form onSubmit={handleSubmit}>
            <div className="form">
              <label>Tài khoản</label>
              <input
                type="text"
                className="form-control"
                placeholder="Nhập tên tài khoản"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="form">
              <label>Server</label>
              <select
                className="form-control"
                value={server}
                onChange={(e) => setServer(e.target.value)}
                required
              >
                <option value="server1">Server 1</option>
                <option value="server2">Server 2</option>
              </select>
            </div>
            <div className="link">
              <Link to={"/login"}>Đăng nhập</Link>
              <Link to={"/register"}>Đăng ký</Link>
            </div>
            <button type="submit">Tiếp tục</button>
          </form>
        </div>
      </div>
    </>
  );
};
export default ForgotPassword;
