import "../../styles/paymentOption.css";
import momoPayment from "../../assets/img/image 5.png";
import cardPayment from "../../assets/img/image 6.png";
import cardPayment1 from "../../assets/img/u_angle-right.png";
import { Link } from "react-router-dom";
import HeaderPayment from "./HeaderPayment";

const PaymentOption = () => {
  return (
    <div className="paymentOption">
      <HeaderPayment />
      <Link to="momo-payment">
      <div className="option">
        <div className="child-option">
          <img src={momoPayment} alt="" />
          <b>Momo</b>
        </div>
        <img src={cardPayment1} alt="" />
      </div>
      </Link>
      <hr />
      <Link to="charge">
        <div className="option">
          <div className="child-option">
            <img src={cardPayment} alt="" />
            <b>Thẻ cào</b>
          </div>
          <img src={cardPayment1} alt="" />
        </div>
      </Link>
      <hr />
    </div>
  );
};

export default PaymentOption;
