import "../../styles/style.css";
import Cookies from "js-cookie";
import pc_button from "../../assets/img/buttons `1 3.png";
import pc_button_hover from "../../assets/img/Property 1=Variant2 (5).png";
import android_button from "../../assets/img/buttons `1 2.png";
import android_button_hover from "../../assets/img/Property 1=Variant2 (4).png";
import android_buttonv2 from "../../assets/img/android v2.png";
import ios_button from "../../assets/img/buttons `1 1.png";
import ios_button_hover from "../../assets/img/Property 1=Variant2 (3).png";
import payment_button from "../../assets/img/buttons `1 4.png";
import payment_button_hover from "../../assets/img/Property 1=Variant2 (6).png";
import ios_buttonv2 from "../../assets/img/IOS IPA.png";
import bg_home from "../../assets/img/Section Hero (1).png";
import bg_home2 from "../../assets/img/Frame 99.png";
import bg_home2_tablet from "../../assets/img/Frame 125.png";
import bg_home2_phone from "../../assets/img/Frame 126.png";
import bg_home3 from "../../assets/img/Frame 100.png";
import bg_home3_tablet from "../../assets/img/Frame 127.png";
import bg_home3_phone from "../../assets/img/Frame 128.png";
import bg_home4 from "../../assets/img/Frame 130.png";
import bg_home4_tablet from "../../assets/img/Frame 131.png";
import bg_home4_phone from "../../assets/img/Frame 132.png";
import bg_home5 from "../../assets/img/Frame 84.png";
import hoinhom from "../../assets/img/hoi nhomm.png";
import hoinhomHover from "../../assets/img/Property 1=Variant2.png";
import fanpage from "../../assets/img/Fanpagee 1.png";
import fanpageHover from "../../assets/img/Property 1=Variant2 (1).png";
import rank from "../../assets/img/bang xep hang 1.png";
import rankHover from "../../assets/img/Property 1=Variant2 (2).png";
import Carousel from "react-bootstrap/Carousel";
import carosel from "../../assets/img/Rectangle 28.png";
import carosel1 from "../../assets/img/Rectangle 29.png";
import carosel2 from "../../assets/img/Rectangle 30.png";
import carosel3 from "../../assets/img/Rectangle 31.png";
import indicators from "../../assets/img/myTexture2danalog2 2.png";
import indicators1 from "../../assets/img/myTexture2danalog2 1.png";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Information from "./Infomation";
import Sidebar from "./Sidebar";
import config from "../../utils/config";
import "../../styles/sidebar.css";

const Home = () => {
  const [index, setIndex] = useState(0);
  const [screenType, setScreenType] = useState(window.innerWidth >= 992);
  const [screenType2, setscreenType2] = useState("desktop");
  const [screenType3, setscreenType3] = useState("desktop");
  const [screenType4, setscreenType4] = useState("desktop");
  const [hover1, setHover1] = useState(false);
  const [hover2, setHover2] = useState(false);
  const [hover3, setHover3] = useState(false);
  const [hover4, setHover4] = useState(false);
  const [hover5, setHover5] = useState(false);
  const [hover6, setHover6] = useState(false);
  const [hover7, setHover7] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setScreenType(window.innerWidth >= 992);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    function determinescreenType2() {
      if (window.innerWidth <= 432) {
        setscreenType2("mobile");
      } else if (window.innerWidth <= 992) {
        setscreenType2("tablet");
      } else {
        setscreenType2("desktop");
      }
    }

    determinescreenType2();

    window.addEventListener("resize", determinescreenType2);

    return () => {
      window.removeEventListener("resize", determinescreenType2);
    };
  }, []);

  useEffect(() => {
    function determinescreenType3() {
      if (window.innerWidth <= 432) {
        setscreenType3("mobile");
      } else if (window.innerWidth <= 992) {
        setscreenType3("tablet");
      } else {
        setscreenType3("desktop");
      }
    }

    determinescreenType3();

    window.addEventListener("resize", determinescreenType3);

    return () => {
      window.removeEventListener("resize", determinescreenType3);
    };
  }, []);
  useEffect(() => {
    function determinescreenType4() {
      if (window.innerWidth <= 432) {
        setscreenType4("mobile");
      } else if (window.innerWidth <= 992) {
        setscreenType4("tablet");
      } else {
        setscreenType4("desktop");
      }
    }

    determinescreenType4();

    window.addEventListener("resize", determinescreenType4);

    return () => {
      window.removeEventListener("resize", determinescreenType4);
    };
  }, []);

  const getImageForscreenType2 = () => {
    switch (screenType2) {
      case "mobile":
        return bg_home2_phone;
      case "tablet":
        return bg_home2_tablet;
      default:
        return bg_home2;
    }
  };

  const getImageForscreenType3 = () => {
    switch (screenType3) {
      case "mobile":
        return bg_home3_phone;
      case "tablet":
        return bg_home3_tablet;
      default:
        return bg_home3;
    }
  };
  const getImageForscreenType4 = () => {
    switch (screenType4) {
      case "mobile":
        return bg_home4_phone;
      case "tablet":
        return bg_home4_tablet;
      default:
        return bg_home4;
    }
  };

  let userData = null;
  try {
    const userDataString = Cookies.get("USER_DATA");
    if (userDataString) {
      userData = JSON.parse(userDataString);
    }
  } catch (error) {
    console.error("Error parsing ACCOUNT_DATA:", error);
  }

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const NextIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="63"
      height="126"
      viewBox="0 0 63 126"
      fill="none"
    >
      <path
        d="M2.74999 120.52L60.1739 63L2.75 5.4803L2.74999 120.52Z"
        fill="#FFE81C"
        stroke="black"
        strokeWidth="4"
      />
    </svg>
  );
  const PrevIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="63"
      height="126"
      viewBox="0 0 63 126"
      fill="none"
    >
      <path
        d="M60.25 120.52L2.82607 63L60.25 5.4803L60.25 120.52Z"
        fill="#FFE81C"
        stroke="black"
        strokeWidth="4"
      />
    </svg>
  );

  const images = [
    {
      src: carosel,
    },
    {
      src: carosel1,
    },
    {
      src: carosel2,
    },
    {
      src: carosel3,
    },
  ];

  return (
    <div className="main">
      <img className="img-home1" src={bg_home} alt="" />
      {userData ? <Information /> : ""}
      <Sidebar />
      <div className="button-download">
        {screenType ? (
          <div>
            <Link
              to={config.DOWNLOAD_LINK_PC}
              target="_blank"
              onMouseEnter={() => setHover4(true)}
              onMouseLeave={() => setHover4(false)}
            >
              {hover4 ? (
                <img src={pc_button_hover} alt="bg" />
              ) : (
                <img src={pc_button} alt="bg" />
              )}
            </Link>
          </div>
        ) : (
          ""
        )}
        <div className="buttonv2">
          <Link
            to={config.DOWNLOAD_LINK_IOS}
            target="_blank"
            onMouseEnter={() => setHover5(true)}
            onMouseLeave={() => setHover5(false)}
          >
            {hover5 ? (
              <img src={ios_button_hover} alt="bg" />
            ) : (
              <img src={ios_button} alt="bg" />
            )}
          </Link>
          {/* <a href="/" className="v2">
            <img src={ios_buttonv2} alt="bg" />
          </a> */}
        </div>
        <div className="buttonv2">
          <Link
            to={config.DOWNLOAD_LINK_ANDROID}
            target="_blank"
            onMouseEnter={() => setHover6(true)}
            onMouseLeave={() => setHover6(false)}
          >
            {hover6 ? (
              <img src={android_button_hover} alt="bg" />
            ) : (
              <img src={android_button} alt="bg" />
            )}
          </Link>
          {/* <a
            className="v2"
            href="/"
            target="_blank"
          >
            <img src={android_buttonv2} alt="bg" />
          </a> */}
        </div>
        <div>
          <a
            className="link"
            href={userData ? "/payment-option" : "/login"}
            onMouseEnter={() => setHover7(true)}
            onMouseLeave={() => setHover7(false)}
          >
            {hover7 ? (
              <img src={payment_button_hover} alt="bg" />
            ) : (
              <img src={payment_button} alt="bg" />
            )}
          </a>
        </div>
      </div>
      <img className="img-home2" src={getImageForscreenType2()} alt="" />
      <img className="img-home3" src={getImageForscreenType3()} alt="" />
      <div className="container-carousel">
        <img className="img-home4" src={getImageForscreenType4()} alt="" />
        <div className="carousel-border-container">
          <div className="carousel-border-container">
            <Carousel
              activeIndex={index}
              onSelect={handleSelect}
              data-bs-theme="dark"
              nextIcon={<NextIcon />}
              prevIcon={<PrevIcon />}
            >
              {images.map((image, idx) => (
                <Carousel.Item key={idx}>
                  <img
                    className="d-block img-slider"
                    src={image.src}
                    alt={`Slide ${idx + 1}`}
                  />
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
      <div className="custom-carousel-indicators">
        {images.map((_, idx) => (
          <img
            key={idx}
            src={index === idx ? indicators : indicators1}
            className={`custom-indicator ${index === idx ? "active" : ""}`}
            onClick={() => setIndex(idx)}
            alt={`Indicator ${idx + 1}`}
            style={{ cursor: "pointer", margin: "10px" }}
          />
        ))}
      </div>

      <div className="home5">
        <img className="img-home5" src={bg_home5} alt="" />
        <div className="link-home5">
          <Link
            as={Link}
            target="_blank"
            to="https://zalo.me/g/bgiwxy397"
            onMouseEnter={() => setHover1(true)} // Only enable hover on non-mobile devices
            onMouseLeave={() => setHover1(false)}
          >
            {hover1 ? (
              <img src={hoinhomHover} alt="" />
            ) : (
              <img src={hoinhom} alt="" />
            )}
          </Link>
          <Link
            as={Link}
            target="_blank"
            to="https://www.facebook.com/dauphainro/"
            onMouseEnter={() => setHover2(true)} // Only enable hover on non-mobile devices
            onMouseLeave={() => setHover2(false)}
          >
            {hover2 ? (
              <img src={fanpageHover} alt="" />
            ) : (
              <img src={fanpage} alt="" />
            )}
          </Link>
          <Link
            as={Link}
            to="/ranking"
            onMouseEnter={() => setHover3(true)} // Only enable hover on non-mobile devices
            onMouseLeave={() => setHover3(false)}
          >
            {hover3 ? (
              <img src={rankHover} alt="" />
            ) : (
              <img src={rank} alt="" />
            )}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;
