import Cookies from "js-cookie";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, useLocation } from "react-router-dom";
import home1 from "../../assets/img/trag chu 1.png";
import home1Hover from "../../assets/img/home1_hover.png";
import home1Active from "../../assets/img/trag chu 1 (1).png";
import home2 from "../../assets/img/trag chu 2.png";
import home2Hover from "../../assets/img/home2_hover.png";
import home3Active from "../../assets/img/trag chu 3 (3).png";
import home3Hover from "../../assets/img/home3_hover.png";
import home4Hover from "../../assets/img/home4_hover.png";
import home5Hover from "../../assets/img/home5_hover.png";
import home3 from "../../assets/img/trag chu 3.png";
import home5 from "../../assets/img/trag chu 5.png";
import home6 from "../../assets/img/trag chu 6.png";
import home6Active from "../../assets/img/trag chu 6 (1).png";
import home5Active from "../../assets/img/trag chu 5 (2).png";
import home6Hover from "../../assets/img/trag chu 3 (2).png";
import home7 from "../../assets/img/trag chu 7.png";
import logo from "../../assets/img/logo1 1.png";
import "../../styles/header.css";
import { useEffect, useState } from "react";

const Header = () => {
  const location = useLocation(); // Get current location
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 992);
  const [hover1, setHover1] = useState(false);
  const [hover2, setHover2] = useState(false);
  const [hover3, setHover3] = useState(false);
  const [hover4, setHover4] = useState(false);
  const [hover5, setHover5] = useState(false);
  const [hover6, setHover6] = useState(false);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 992);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let userData = null;

  try {
    const userDataString = Cookies.get("USER_DATA");
    if (userDataString) {
      userData = JSON.parse(userDataString);
    }
  } catch (error) {
    console.error("Error parsing ACCOUNT_DATA:", error);
  }

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const closeNav = () => {
    setExpanded(false);
  };

  const isActive1 = location.pathname === "/";
  const isActive2 = location.pathname === "/login";
  const isActive3 = location.pathname === "/register";
  const isActive4 = location.pathname === "/milestone";

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      expanded={expanded}
      onToggle={handleToggle}
      variant="dark"
    >
      <Container>
        <Navbar.Brand as={Link} to="/" onClick={closeNav}>
          <img className="logo-img" src={logo} alt="" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav>
            <Nav.Link
              as={Link}
              to="/"
              onClick={closeNav}
              onMouseEnter={() => !isMobile && setHover1(true)} // Only enable hover on non-mobile devices
              onMouseLeave={() => !isMobile && setHover1(false)}
              className={isActive1 ? "active" : ""}
            >
              {isMobile ? (
                "Trang chủ"
              ) : isActive1 ? (
                <img src={home1Active} alt="Home Active" />
              ) : hover1 ? (
                <img src={home1Hover} alt="Home" />
              ) : (
                <img src={home1} alt="Home" />
              )}
            </Nav.Link>
            <Nav.Link
              as={Link}
              target="_blank"
              to="https://www.facebook.com/dauphainro/"
              onClick={closeNav}
              onMouseEnter={() => !isMobile && setHover2(true)}
              onMouseLeave={() => !isMobile && setHover2(false)}
            >
              {isMobile ? (
                "Fanpage"
              ) : hover2 ? (
                <img src={home2Hover} alt="Fanpage" />
              ) : (
                <img src={home2} alt="Fanpage" />
              )}
            </Nav.Link>
            {/* <Nav.Link as={Link} to="/ranking">
              Đua top
            </Nav.Link> */}
            {/* <a className="link" href={userData ? "/payment-option" : "/login"}>
              Nạp thẻ
            </a> */}
            <Nav.Link
              as={Link}
              target="_blank"
              to="https://zalo.me/g/bgiwxy397"
              onClick={closeNav}
              onMouseEnter={() => !isMobile && setHover3(true)}
              onMouseLeave={() => !isMobile && setHover3(false)}
            >
              {isMobile ? (
                "Hội nhóm"
              ) : hover3 ? (
                <img src={home3Hover} alt="Hội nhóm" />
              ) : (
                <img src={home3} alt="Hội nhóm" />
              )}
            </Nav.Link>
          </Nav>
          {userData && (
            <Nav>
              <a
                href={userData ? "/milestone" : "/login"}
                onMouseEnter={() => !isMobile && setHover6(true)}
                onMouseLeave={() => !isMobile && setHover6(false)}
                onClick={closeNav}
                className={isActive2 ? "active link" : "link"}
              >
                {isMobile ? (
                  "Mốc nạp"
                ) : isActive4 ? (
                  <img src={home3Active} alt="Mốc nạp" />
                ) : hover6 ? (
                  <img src={home6Hover} alt="Mốc nạp" />
                ) : (
                  <img src={home7} alt="Mốc nạp" />
                )}
              </a>
            </Nav>
          )}
          {!userData && (
            <Nav>
              <Nav.Link
                as={Link}
                to="/login"
                onMouseEnter={() => !isMobile && setHover4(true)}
                onMouseLeave={() => !isMobile && setHover4(false)}
                onClick={closeNav}
                className={isActive2 ? "active" : ""}
              >
                {isMobile ? (
                  "Đăng nhập"
                ) : isActive2 ? (
                  <img src={home6Active} alt="Đăng nhập" />
                ) : hover4 ? (
                  <img src={home4Hover} alt="Đăng nhập" />
                ) : (
                  <img src={home6} alt="Đăng nhập" />
                )}
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/register"
                onMouseEnter={() => !isMobile && setHover5(true)}
                onMouseLeave={() => !isMobile && setHover5(false)}
                onClick={closeNav}
                className={isActive3 ? "active" : ""}
              >
                {isMobile ? (
                  "Đăng ký"
                ) : isActive3 ? (
                  <img src={home5Active} alt="Đăng ký" />
                ) : hover5 ? (
                  <img src={home5Hover} alt="Đăng ký" />
                ) : (
                  <img src={home5} alt="Đăng ký" />
                )}
              </Nav.Link>
            </Nav>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
export default Header;
