import React, { useEffect, useRef, useState } from "react";
import "../../styles/milestone.css";
import button from "../../assets/img/Frame 37.png";
import { Col, Row } from "reactstrap";
import final100 from "../../assets/img/100.png";
import final200 from "../../assets/img/200.png";
import final500 from "../../assets/img/500.png";
import final1tr from "../../assets/img/1tr.png";
import final2tr from "../../assets/img/2tr.png";
import final5tr from "../../assets/img/5tr.png";
import final10tr from "../../assets/img/10tr.png";
import final20tr from "../../assets/img/20tr.png";
import final200tr from "../../assets/img/200tr.png";
import buttonActive from "../../assets/img/Buttons Active.png";
import buttonDeActive from "../../assets/img/Buttons Deactive.png";
import buttonReceived from "../../assets/img/Buttons Received.png";
import request from "../../utils/request";
import Cookies from "js-cookie";
import NotificationAlert from "react-notification-alert";
import Information from "../auth/Infomation";
import Sidebar from "../auth/Sidebar";

const packages = [
  { amount: 100000, img: final100 },
  { amount: 200000, img: final200 },
  { amount: 500000, img: final500 },
  { amount: 1000000, img: final1tr },
  { amount: 2000000, img: final2tr },
  { amount: 5000000, img: final5tr },
  { amount: 10000000, img: final10tr },
  { amount: 20000000, img: final20tr },
];

const MileStone = () => {
  const [totalCoin, setTotalCoin] = useState(0);

  const notificationAlertRef = useRef(null);
  const [packageStatuses, setPackageStatuses] = useState([]);
  let userData = null;
  try {
    const userDataString = Cookies.get("USER_DATA");
    if (userDataString) {
      userData = JSON.parse(userDataString);
    }
  } catch (error) {
    console.error("Error parsing USER_DATA:", error);
  }

  const notifySuccess = () => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            <span className="alert-title" data-notify="title">
              Nhận phần thưởng thành công.{" "}
            </span>
            <br />
            <span data-notify="message">
              Vui lòng kiểm tra trong rương quà!
            </span>
          </span>
        </div>
      ),
      type: "success",
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const notifyDanger = (message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {message.title}
          </span>
          <br />
          <span data-notify="message">{message.details}</span>
        </div>
      ),
      type: "danger",
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  useEffect(() => {
    if (userData && userData.username) {
      request
        .get(`/Auth/CheckUsername?username=${userData.username}&server=${userData.server}`)
        .then((response) => {
          setTotalCoin(response.data.totalNapThe);
        })
        .catch((error) => {
          console.error("Error fetching totalNapThe:", error);
        });
    }
  }, [userData]);

  useEffect(() => {
    const fetchPackageStatuses = async () => {
      if (!userData || !userData.id) {
        console.error("Invalid user data");
        return;
      }

      const statuses = await Promise.all(
        packages.map(async (pkg) => {
          try {
            const response = await request.get(`/RewardItems/CheckPackage`, {
              params: { accountId: userData.id, package: pkg.amount, server: userData.server },
            });
            return { amount: pkg.amount, ...response.data };
          } catch (error) {
            console.error(error);
            return { amount: pkg.amount, canReceive: false, message: "Error" };
          }
        })
      );
      setPackageStatuses(statuses);
    };

    fetchPackageStatuses();
  }, []);

  const getButtonImage = (amount) => {
    const status = packageStatuses.find((pkg) => pkg.amount === amount);
    if (!status) return buttonDeActive;
    if (status.message && status.message.includes("Already exists"))
      return buttonReceived;
    if (status.canReceive) return buttonActive;
    if (status.message && status.message.includes("Not enough balance"))
      return buttonDeActive;
    return buttonDeActive;
  };

  const handleButtonClick = async (amount) => {
    const status = packageStatuses.find((pkg) => pkg.amount === amount);
    if (status && status.canReceive) {
      try {
        const response = await request.post(`/RewardItems/RewardItems`, {
          accountId: Number(userData.id),
          package: amount,
          server: userData.server,
        });

        if (
          response.data.message === "Server login failed. Please log in again."
        ) {
          notifyDanger({
            title: "Lỗi đăng nhập.",
            details: "Vui lòng thoát game để có thể nhận phần thưởng!",
          });
          return;
        }

        notifySuccess();
        // Update the package status after claiming reward
        const updatedStatuses = packageStatuses.map((pkg) =>
          pkg.amount === amount
            ? { ...pkg, canReceive: false, message: "Already exists" }
            : pkg
        );
        setPackageStatuses(updatedStatuses);
      } catch (error) {
        notifyDanger({
          details: "Vui lòng thoát game để có thể nhận phần thưởng!",
        });
      }
    }
  };

  const renderPackages = () => {
    const rows = [];
    for (let i = 0; i < packages.length; i += 4) {
      rows.push(
        <Row xs="1" sm="2" md="4" className="card-milestone noGutters" key={i}>
          {packages.slice(i, i + 4).map((pkg, index) => (
            <Col xs="12" sm="3" md="2" key={index}>
              <div
                className="button-container"
                onClick={() => handleButtonClick(pkg.amount)}
              >
                <img className="img-milestone" src={pkg.img} alt="" />
                <img
                  className="button"
                  src={getButtonImage(pkg.amount)}
                  alt=""
                />
              </div>
            </Col>
          ))}
        </Row>
      );
    }
    return rows;
  };

  console.log(totalCoin);

  return (
    <React.Fragment>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <div className="milestone">
        <img className="bg-milestone" src={button} alt="" />
        {userData ? <Information /> : ""}
        {userData ? <Sidebar /> : ""}
        <div className="text-milestone">
          <p>
            Tổng nạp:{" "}
            {totalCoin.toLocaleString("vi-VN", {
              style: "decimal",
            })}{" "}
            VNĐ
          </p>
          <p>
            *Lưu ý: Tổng số tiền nạp có cộng dồn, mỗi tài khoản chỉ có thể nhận
            1 lần
          </p>
        </div>
        <div>
          {renderPackages()}
          <Row xs="1" sm="2" md="4" className="card-milestone noGutters">
            <Col xs="12" sm="3" md="2">
              <div className="button-container">
                <img className="img-milestone" src={final200tr} alt="" />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MileStone;
