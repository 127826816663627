import React, { useRef, useState } from "react";
import "../../styles/forgotPassword2.css";
import { useNavigate, useParams } from "react-router-dom";
import request from "../../utils/request";
import NotificationAlert from "react-notification-alert";
import Cookies from "js-cookie";

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const notificationAlertRef = useRef(null);
  const { userName } = useParams();

  const navigate = useNavigate();

  let userData = null;
  try {
    const userDataString = Cookies.get("USER_DATA");
    if (userDataString) {
      userData = JSON.parse(userDataString);
    }
  } catch (error) {
    console.error("Error parsing ACCOUNT_DATA:", error);
  }

  const notifySuccess = () => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            Mật khẩu đã được đặt lại thành công.
          </span>
        </div>
      ),
      type: "success",
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const notifyDanger = (message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {message.title}
          </span>
          <br />
          <span data-notify="message">{message.details}</span>
        </div>
      ),
      type: "danger",
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmNewPassword) {
      notifyDanger({
        title: "Mật khẩu mới và xác nhận mật khẩu mới không khớp!",
        details: `Vui lòng kiểm tra các trường đã điền`,
      });
      return;
    }

    // Kiểm tra mật khẩu có trên 6 ký tự và không chứa dấu cách
    if (newPassword.length < 6 || newPassword.includes(" ")) {
      notifyDanger({
        title: "Mật khẩu không hợp lệ!",
        details: `Mật khẩu phải dài hơn 6 ký tự và không chứa dấu cách.`,
      });
      return;
    }

    try {
      const response = await request.put(`/Auth/ChangePassword?server=${userData.server}`, {
        username: userName,
        oldPassword: oldPassword,
        NewPassword: newPassword,
      });

      if (response.status === 200) {
        notifySuccess();
        navigate("/");
      } else {
        notifyDanger({
          title: " Đã xảy ra lỗi trong quá trình đặt lại mật khẩu.",
          details: `Vui lòng kiểm tra các trường đã điền`,
        });
      }
    } catch (error) {
      console.error("Error resetting password:", error);
      notifyDanger({
        title: " Đã xảy ra lỗi trong quá trình đặt lại mật khẩu.",
        details: `Vui lòng kiểm tra các trường đã điền`,
      });
    }
  };
  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <div className="forgot-password2-container">
        <div className="form-forgot-password2 form-change-password2">
          <h1>ĐỔI MẬT KHẨU</h1>
          <form onSubmit={handleSubmit}>
            <div className="form">
              <label>Mật khẩu cũ</label>
              <input
                type="password"
                className="form-control"
                placeholder="Nhập mật khẩu cũ"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
              />
            </div>
            <div className="form">
              <label>Mật khẩu mới</label>
              <input
                type="password"
                className="form-control"
                placeholder="Nhập mật khẩu mới"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>
            <div className="form">
              <label>Xác nhận mật khẩu mới</label>
              <input
                type="password"
                className="form-control"
                placeholder="Mật khẩu"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
              />
            </div>
            <button style={{ marginTop: "60px" }} type="submit">
              XÁC NHẬN
            </button>
          </form>
        </div>
      </div>
    </>
  );
};
export default ChangePassword;
