import sideBar from "../../assets/img/Phong ngu 8.png";
import buttonSideBar1 from "../../assets/img/Group 30.png";
import buttonSideBar2 from "../../assets/img/Group 31.png";
import buttonSideBar3 from "../../assets/img/Group 32.png";
import buttonSideBar4 from "../../assets/img/Group 33.png";
import "../../styles/sidebar.css";
import { Link } from "react-router-dom";
import config from "../../utils/config";
import Cookies from "js-cookie";
import { useState } from "react";

const Sidebar = () => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  let userData = null;

  try {
    const userDataString = Cookies.get("USER_DATA");
    if (userDataString) {
      userData = JSON.parse(userDataString);
    }
  } catch (error) {
    console.error("Error parsing ACCOUNT_DATA:", error);
  }

  return (
    <div
      className={`sidebar-container ${
        isSidebarVisible ? "sidebar-container-open" : "sidebar-container-close"
      }`}
      style={{
        transform: isSidebarVisible ? "translateX(0)" : "translateX(100%)",
      }}
      onClick={toggleSidebar}
    >
      {!isSidebarVisible && (
        <div className="sidebar-container-close" onClick={toggleSidebar}>
          {/* Compact view toggle icon */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="110"
            viewBox="0 0 31 114"
            fill="none"
          >
            <path d="M0 15.6202L31 0V114L0 95.9487V15.6202Z" fill="#24B0FF" />
            <path
              d="M17.75 37H26L17 55.75L26 76H17L8 55.75L17.75 37Z"
              fill="white"
            />
          </svg>
        </div>
      )}
      {isSidebarVisible && (
        <div className="sidebar-container-open">
          <img className="sidebar-img" src={sideBar} alt="" />
          <div className="button-sidebar">
            <Link to={config.DOWNLOAD_LINK_IOS} target="_blank">
              <img src={buttonSideBar1} alt="" />
            </Link>
            <Link to={config.DOWNLOAD_LINK_ANDROID} target="_blank">
              <img src={buttonSideBar2} alt="" />
            </Link>
            <a className="link" href={userData ? "/payment-option" : "/login"}>
              <img src={buttonSideBar3} alt="" />
            </a>
            <Link as={Link} to="/ranking">
              <img src={buttonSideBar4} alt="" />
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};
export default Sidebar;
