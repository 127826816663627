import React, { useEffect, useState } from "react";
import HeaderPayment from "./HeaderPayment";
import "../../styles/paymentHistory.css";
import request from "../../utils/request";
import Cookies from "js-cookie";
import { Button } from "reactstrap";

const PaymentHistory = () => {
  let userData = null;
  const [historyData, setHistoryData] = useState([]);
  const [error, setError] = useState(false);
  const [displayedCount, setDisplayedCount] = useState(10);

  const handleShowMore = () => {
    setDisplayedCount(historyData.length); // Cập nhật để hiển thị toàn bộ lịch sử
  };

  try {
    const userDataString = Cookies.get("USER_DATA");
    if (userDataString) {
      userData = JSON.parse(userDataString);
    }
  } catch (error) {
    console.error("Error parsing ACCOUNT_DATA:", error);
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await request.get(
          `Charge/HistoryPayment?accountId=${userData.id}&server=${userData.server}`
        );
        if (response.status === 200) {
          setHistoryData(response.data);
        } else {
          setError(true);
        }
      } catch (err) {
        // Corrected variable name to 'err'
        setError(true);
        console.error("Error fetching payment history:", err);
      }
    };

    fetchData(); // Call fetchData within useEffect
  }, [userData.id]); // Ensure userData and request are included if they are state/props

  const applyBonusForMomo = (data) => {
    if (data.telco === "0") {
      // Assuming "0" indicates Momo
      let bonus = 0;
      const valuesWith10Percent = [10000, 20000, 50000, 100000];
      const valuesWith12Percent = [200000, 500000];
      const valuesWith15Percent = [1000000, 2000000];
      const valueWith17Percent = 5000000;
      const valueWith20Percent = 10000000;
      const valueWith23Percent = 20000000;

      if (valuesWith10Percent.includes(data.realValue)) {
        bonus = 0.1;
      } else if (valuesWith12Percent.includes(data.realValue)) {
        bonus = 0.12;
      } else if (valuesWith15Percent.includes(data.realValue)) {
        bonus = 0.15;
      } else if (data.realValue === valueWith17Percent) {
        bonus = 0.17;
      } else if (data.realValue === valueWith20Percent) {
        bonus = 0.2;
      } else if (data.realValue === valueWith23Percent) {
        bonus = 0.23;
      }

      data.realValue += data.realValue * bonus;
    }
    return data;
  };

  const formatDate = (date) => {
    // Định dạng ngày tháng và thời gian
    // PadStart được sử dụng để đảm bảo rằng mỗi phần tử là 2 chữ số
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth trả về 0-11 nên cần +1
    const year = date.getFullYear();

    // Kết hợp các phần tử lại với nhau để tạo ra chuỗi định dạng cuối cùng
    return `${hours}:${minutes}:${seconds} ${day}/${month}/${year}`;
  };

  return (
    <div className="payment-history-container">
      <HeaderPayment />
      {!error &&
        historyData.slice(0, displayedCount).map(
          (data,index) => {
            const updatedData = applyBonusForMomo({...data}); 
            return(
            <div key={index}>
              <div className="payment-history">
                <div className="left">
                  <h5>
                    {data.realValue.toLocaleString("vi-VN", {
                      style: "decimal",
                    })}{" "}
                    VNĐ
                  </h5>
                  <p>
                    Thời gian: {formatDate(new Date(data.createTime * 1000))}
                  </p>
                  {data.telco === "0" && <p>Phương thức: Momo</p>}
                  {data.telco !== "0" && <p>Phương thức: Thẻ cào</p>}
                  {data.telco !== "0" && (
                    <p>
                      Mã nạp: {data.code} | Seri: {data.serial}
                    </p>
                  )}
                </div>
                <div className="right">
                  <h5>
                    {updatedData.realValue.toLocaleString("vi-VN", {
                      style: "decimal",
                    })}{" "}
                    ToriCoins
                  </h5>
                  {data.status === 99 && (
                    <p style={{ color: "#2280ec" }}>Đang xử lý</p>
                  )}
                  {(data.status === 1 || data.status === 2) && (
                    <p style={{ color: "#21C01E" }}>Thành công</p>
                  )}
                  {(data.status === 100 ||
                    data.status === 0 ||
                    data.status === 102 ||
                    data.status === 3) && (
                    <p style={{ color: "red" }}>Thất bại</p>
                  )}
                </div>
              </div>
              <hr />
            </div>
          )}
        )}
      {historyData.length > displayedCount && ( // Nút "Xem thêm" chỉ hiển thị khi có thêm giao dịch để hiển thị
        <div className="show-more-container">
          <Button
            onClick={handleShowMore}
            className="show-more-btn"
            style={{ marginTop: "10px", marginBottom: "20px" }}
          >
            Xem thêm
          </Button>
        </div>
      )}
      {error && (
        <p style={{ marginTop: "60px", fontWeight: "600" }}>
          {" "}
          Bạn chưa có giao dịch nào gần đây!
        </p>
      )}
    </div>
  );
};
export default PaymentHistory;
